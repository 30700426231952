import React, { useEffect, useRef, useState } from "react";
import * as fabric from "fabric";
import {
  AiOutlineVerticalAlignMiddle,
  AiOutlineAlignLeft,
  AiOutlineAlignRight,
} from "react-icons/ai";
import { ImMug } from "react-icons/im"
import { MdOutlineAlignHorizontalCenter, MdOutlineAlignVerticalCenter } from "react-icons/md";
import {
  FaLayerGroup,
  FaRegArrowAltCircleDown,
  FaRegArrowAltCircleUp,
  FaTextHeight,
  FaImage,
  FaTrash,
  FaDownload,
  FaSave,
  FaUpload,
  FaFileUpload,
  FaFileDownload,
  FaFileExport,
  FaCartPlus,
  FaExpandArrowsAlt,
  FaRedoAlt,
} from "react-icons/fa";
import { GrInProgress } from "react-icons/gr";
import { BsTextLeft, BsTextCenter, BsTextRight, BsJustify } from "react-icons/bs";
import { BiBold, BiItalic, BiUnderline } from "react-icons/bi";
import axios from "axios";
import { useLocation } from "react-router-dom";

// Aggiungi il metodo al prototipo di Fabric
fabric.Canvas.prototype.sortObjects = function () {
  this._objects.sort((a, b) => (a.zIndex || 0) - (b.zIndex || 0));
};

const Editor = () => {
  const location = useLocation();
  const [isAdmin, setIsAdmin] = useState(false);

  // Stato per le opzioni
  const [isCropEnabled, setIsCropEnabled] = useState(false);
  const [isGradientEnabled, setIsGradientEnabled] = useState(false);

  const [isCanvasSize, setIsCanvasSize] = useState(false);
  const [isCanvasLoaded, setIsCanvasLoaded] = useState(false);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    setIsAdmin(params.get("admin") === "1");
  }, [location]);

  const canvasRef = useRef();
  const exportCanvasRef = useRef(); // Canvas nascosto per alta qualità

  // const [canvasSize, setCanvasSize] = useState({ width: 1110, height: 520 }); // Responsive dimensions
  const [canvasSize, setCanvasSize] = useState({ width: 768, height: 360 }); // Responsive dimensions
  const realCanvasSize = { width: 2775, height: 1300 }; // High-quality dimensions
  const [selectedFont, setSelectedFont] = useState("Arial");
  const [isLoading, setIsLoading] = useState(false); // Stato per mostrare il caricamento

  // Funzione per calcolare dimensioni canvas responsive
  const calculateCanvasSize = () => {
    const maxWidth = 1110; // Larghezza massima
    const minWidth = 320; // Larghezza minima per mobile
    const aspectRatio = 1110 / 520; // Rapporto larghezza/altezza

    const containerWidth = Math.max(
      Math.min(window.innerWidth - 50, maxWidth),
      minWidth
    );
    const containerHeight = containerWidth / aspectRatio;

    return { width: containerWidth, height: containerHeight };
  };

  useEffect(() => {
    // Imposta la dimensione del canvas all'avvio
    setCanvasSize(calculateCanvasSize());
    setIsCanvasSize(true);

    // NON NECESSARIO AL MOMENTO IL RESIZE RESPONSIVE. IMPORTANTE RESPONSIVE SOLO AL CARICAMENTO
    // QUANDO LO RIABILITEREMO, ANDRÀ VERIFICATA LA PARTE DI SAVE E LOAD
    // Aggiungi un listener per ridimensionare il canvas quando cambia la finestra
    // const handleResize = () => setCanvasSize(calculateCanvasSize());
    // window.addEventListener("resize", handleResize);
    //
    // // Cleanup del listener al dismount del componente
    // return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (!isCanvasSize) return;
    // Canvas visibile
    const canvas = new fabric.Canvas("editorCanvas", {
      width: canvasSize.width,
      height: canvasSize.height,
      backgroundColor: "#ffffff",
      preserveObjectStacking: true,
    });
    canvasRef.current = canvas;

    // Canvas nascosto per esportazione
    const exportCanvas = new fabric.StaticCanvas(null, {
      width: realCanvasSize.width,
      height: realCanvasSize.height,
      backgroundColor: "#ffffff",
      preserveObjectStacking: true,
    });
    exportCanvasRef.current = exportCanvas;

    return () => {
      if (canvas) canvas.dispose();
      if (exportCanvas) exportCanvas.dispose();
    };
  }, [canvasSize]);

  const hasLoaded = useRef(false);

  useEffect(() => {
    if (!hasLoaded.current && isCanvasSize) {
      loadStateFromIndexedDB();
      setIsCanvasLoaded(true);
      hasLoaded.current = true;
    }
  }, [canvasSize]);

  useEffect(() => {
    let saveInterval;

    if (isCanvasLoaded && canvasRef.current) {
      const saveCanvasState = () => {
        // console.log("Salvataggio periodico dello stato del canvas...");
        saveStateToIndexedDB(canvasRef.current);
      };

      saveInterval = setInterval(saveCanvasState, 5000);
    }

    return () => {
      if (saveInterval) {
        clearInterval(saveInterval);
      }
    };
  }, [isCanvasLoaded]);

  const resetCanvas = async () => {
    const canvas = canvasRef.current;

    if (!canvas) {
      alert("Canvas non inizializzato!");
      return;
    }

    canvas.clear();
    await resetCanvasStateInIndexedDB();
    canvas.renderAll();
  };

  const openDatabase = () => {
    return new Promise((resolve, reject) => {
      const request = indexedDB.open("CanvasEditorDB", 1);

      request.onupgradeneeded = (event) => {
        const db = event.target.result;
        if (!db.objectStoreNames.contains("canvasState")) {
          db.createObjectStore("canvasState", { keyPath: "id" });
        }
      };

      request.onsuccess = (event) => resolve(event.target.result);
      request.onerror = (event) => reject(event.target.error);
    });
  };

  const saveStateToIndexedDB = async () => {
    const canvas = canvasRef.current;
    const db = await openDatabase();
    const transaction = db.transaction("canvasState", "readwrite");
    const store = transaction.objectStore("canvasState");

    const json = canvas.toJSON();
    // console.log('save from indexedDB', json);
    // console.log('canvasSize', canvasSize.width, canvasSize.height);
    const state = {
      id: "canvasState",
      data: json,
      canvasSize: { width: canvasSize.width, height: canvasSize.height }, // Salva dimensioni del canvas
    };

    store.put(state);

    // transaction.oncomplete = () => console.log("Stato salvato in IndexedDB!");
    transaction.onerror = (event) => console.error("Errore salvando lo stato:", event.target.error);
  };

  const loadStateFromIndexedDB = async () => {
    const canvas = canvasRef.current;
    const db = await openDatabase();
    const transaction = db.transaction("canvasState", "readonly");
    const store = transaction.objectStore("canvasState");

    const request = store.get("canvasState");

    request.onsuccess = () => {
      const result = request.result;

      // console.log("Load From IndexedDB:", json);
      if (result && result.data) {
        const json = result.data;
        // console.log('read from indexedDB', json);
        // console.log('canvasSize', result.canvasSize.width, result.canvasSize.height);
        const savedCanvasSize = result.canvasSize || { width: canvasSize.width, height: canvasSize.height };

        // Calcola il fattore di scala
        const scaleFactor = canvasSize.width / savedCanvasSize.width;
        console.log('scaleFactor', scaleFactor);

        // loadFromJSON has been a Promise in v6
        canvas.loadFromJSON(json).then(function(){
          const objects = canvas.getObjects();
          console.log(objects)
          if (objects.length > 0) {
            objects.forEach((obj) => {
              console.log('obj.scaleX', obj.scaleX, 'obj.scaleY', obj.scaleY);
              obj.scaleX = obj.scaleX * scaleFactor;
              obj.scaleY = obj.scaleY * scaleFactor;
              obj.left = (obj.left || 0) * scaleFactor;
              obj.top = (obj.top || 0) * scaleFactor;
              obj.setCoords(); // Aggiorna le coordinate
            });
          }
          canvas.renderAll()
        })
      } else {
        console.log("Nessuno stato salvato trovato in IndexedDB.");
      }
    };

    request.onerror = (event) => console.error("Errore caricando lo stato:", event.target.error);
  };

  const resetCanvasStateInIndexedDB = async () => {
    const db = await openDatabase();
    const transaction = db.transaction("canvasState", "readwrite");
    const store = transaction.objectStore("canvasState");

    store.delete("canvasState");

    transaction.oncomplete = () => console.log("Stato resettato in IndexedDB!");
    transaction.onerror = (event) => console.error("Errore resettando lo stato:", event.target.error);
  };

  const loadProject = (event) => {
    const canvas = canvasRef.current;

    if (!canvas) {
      alert("Canvas non inizializzato!");
      return;
    }

    const file = event.target.files[0];
    if (!file) {
      alert("Nessun file selezionato!");
      return;
    }

    const reader = new FileReader();
    reader.onload = (e) => {
      const json = e.target.result;
      console.log("JSON caricato:", json);
      // Carica il JSON nel canvas
      // loadFromJSON has been a Promise in v6
      canvas.loadFromJSON(json).then(function(){canvas.renderAll()})
    };
    reader.readAsText(file);
  };

  const saveProject = () => {
    const canvas = canvasRef.current;

    if (!canvas) {
      alert("Canvas non inizializzato!");
      return;
    }

    const json = canvas.toJSON(); // Serializza il canvas in JSON
    const blob = new Blob([JSON.stringify(json)], { type: "application/json" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = "mugmakershop.json";
    link.click();
  };

  const generateMugTexture = async () => {
    setIsLoading(true);

    saveStateToIndexedDB(canvasRef.current);

    const dataURL = exportHighQualityImage();
    // Il valore base64 è quello dopo 'data:image/png;base64,'
    const base64Image = dataURL.split(",")[1];

    if (!base64Image) {
      alert("Errore nel generare l'immagine.");
      return;
    }

    let uuid = null;

    try {
      // console.log(base64Image);
      // Fai una POST al server che restituisce l'UUID
      const response = await axios.post(
        "https://cdn.mugmakershop.com/generate-mug",
        {
          image: base64Image,
          options: {
            gradient: isGradientEnabled,
            crop: isCropEnabled,
          },
        },
        {
          headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer rOD4hdQk5ImDxmBPgOx03a55PjBtpYt6QPXzEvTZcIAmr51vlmzeakbtVbhMj7JC"
          }
        }
      );

      // console.log("Risposta dal server:", response);

      if (response.status !== 200) {
        throw new Error("Errore durante l'invio dell'immagine al server.");
      }

      uuid = response.data.uuid;
      console.log("UUID ricevuto:", uuid);
    } catch (error) {
      console.error("Errore durante la generazione della tazza:", error);
      alert("Errore durante la generazione della tazza. Riprova!");
    } finally {
      setIsLoading(false);
    }

    if (uuid) {
      if (isAdmin) {
        window.parent.location.href = `https://viewer.mugmakershop.com/cms?p=${uuid}`;
      } else {
        window.parent.location.href = `https://www.mugmakershop.com/products/mug-11oz?p=${uuid}`;
      }
      // window.open(`https://www.mugmakershop.com/products/mug-11oz?p=${uuid}`, '_blank');
    }
  };

  const exportHighQualityImage = () => {
    const canvas = canvasRef.current;

    if (!canvas) {
      alert("Canvas non inizializzato!");
      return;
    }

    // Calcola il fattore di scala per l'alta qualità
    const scaleFactor = realCanvasSize.width / canvasSize.width;

    // Ottieni l'immagine del canvas in formato base64
    return canvas.toDataURL({
      format: "jpeg",
      multiplier: scaleFactor, // Scala il canvas per esportare in alta qualità
    });
  };

  const downloadHighQualityImage = () => {
    const dataURL = exportHighQualityImage();

    // Crea un link per scaricare l'immagine
    const link = document.createElement("a");
    link.href = dataURL;
    link.download = "high_quality_design.png";
    link.click();
  };

  const addImage = (event) => {
    const file = event.target.files[0];

    if (!file) {
      alert("Nessun file selezionato!");
      return;
    }

    if (!file.type.startsWith("image/")) {
      alert("Per favore, carica un file immagine!");
      return;
    }

    const reader = new FileReader();

    reader.onload = () => {
      const imageURL = reader.result;

      const img = new Image();
      img.src = imageURL;

      img.onload = () => {
        const aspectRatio = img.width / img.height;

        let targetWidth = 400;
        let targetHeight = 400 / aspectRatio;

        if (targetHeight > 400) {
          targetHeight = 400;
          targetWidth = 400 * aspectRatio;
        }

        const fabricImage = new fabric.Image(img, {
          left: (800 - targetWidth) / 2,
          top: (400 - targetHeight) / 2,
          scaleX: targetWidth / img.width,
          scaleY: targetHeight / img.height,
        });

        canvasRef.current.add(fabricImage);
        canvasRef.current.setActiveObject(fabricImage);
        canvasRef.current.renderAll();
      };

      img.onerror = () => {
        console.error("Errore nel caricamento dell'immagine.");
      };
    };

    reader.readAsDataURL(file);
  };

const fitObjectToCanvas = () => {
  const canvas = canvasRef.current;
  const activeObject = canvas.getActiveObject();

  if (!activeObject) {
    alert("Seleziona un oggetto (immagine o testo) per adattarlo al canvas!");
    return;
  }

  // Dimensioni del canvas
  const canvasWidth = canvasSize.width;
  const canvasHeight = canvasSize.height;

  // Usa le dimensioni originali dell'oggetto, senza scala
  const originalWidth = activeObject.width;
  const originalHeight = activeObject.height;

  console.log("Dimensioni canvas:", canvasWidth, canvasHeight);
  console.log("Dimensioni originali dell'oggetto:", originalWidth, originalHeight);

  // Calcola i fattori di scala per adattare l'oggetto al canvas
  const scaleX = canvasWidth / originalWidth;
  const scaleY = canvasHeight / originalHeight;

  // Determina il fattore di scala appropriato (basato sul formato dell'oggetto)
  const scale = originalWidth > originalHeight ? scaleX : scaleY;

  // Applica il fattore di scala
  activeObject.scaleX = scale;
  activeObject.scaleY = scale;

  // Calcola la nuova posizione per centrare l'oggetto
  activeObject.left = (canvasWidth - originalWidth * scale) / 2;
  activeObject.top = (canvasHeight - originalHeight * scale) / 2;

  console.log("Nuove dimensioni (scaled):", originalWidth * scale, originalHeight * scale);
  console.log("Nuova posizione:", activeObject.left, activeObject.top);

  activeObject.setCoords(); // Aggiorna le coordinate dell'oggetto
  canvas.renderAll(); // Aggiorna il canvas
};

  // Funzione per duplicare l'oggetto selezionato
  const duplicateSelectedObject = () => {
    const canvas = canvasRef.current;
    const activeObject = canvas.getActiveObject();

    if (!activeObject) {
      alert("Seleziona un oggetto da duplicare!");
      return;
    }

    // Verifica il tipo di oggetto
    if (activeObject.type === "image") {
      // Clona un'immagine
      const clonedImage = new fabric.Image(activeObject.getElement(), {
        left: activeObject.left + 20, // Sposta leggermente il duplicato
        top: activeObject.top + 20,
        scaleX: activeObject.scaleX,
        scaleY: activeObject.scaleY,
        angle: activeObject.angle,
        opacity: activeObject.opacity,
      });

      canvas.add(clonedImage);
      canvas.setActiveObject(clonedImage);
      canvas.renderAll();
    } else if (activeObject.type === "textbox") {
      // Clona un testo
      const clonedText = new fabric.Textbox(activeObject.text, {
        left: activeObject.left + 20, // Sposta leggermente il duplicato
        top: activeObject.top + 20,
        fontSize: activeObject.fontSize,
        fontFamily: activeObject.fontFamily,
        fill: activeObject.fill,
        scaleX: activeObject.scaleX,
        scaleY: activeObject.scaleY,
        angle: activeObject.angle,
        opacity: activeObject.opacity,
        underline: activeObject.underline,
        fontWeight: activeObject.fontWeight,
        fontStyle: activeObject.fontStyle,
        textAlign: activeObject.textAlign,
      });

      canvas.add(clonedText);
      canvas.setActiveObject(clonedText);
      canvas.renderAll();
    } else {
      alert("Questo tipo di oggetto non può essere duplicato.");
    }
  };

  const deleteSelectedObject = () => {
    const canvas = canvasRef.current;
    const activeObject = canvas.getActiveObject();

    if (!activeObject) {
      alert("Seleziona un oggetto da eliminare!");
      return;
    }

    // Rimuovi l'oggetto dal canvas
    canvas.remove(activeObject);
    canvas.renderAll();
  };

  const handleImageUpload = () => {
    document.getElementById("imageUploader").click();
  };

  const addText = () => {
    const canvas = canvasRef.current;

    const text = new fabric.Textbox("Modifica il testo", {
      left: 100,
      top: 100,
      fontSize: 20,
      fontFamily: selectedFont,
      fill: "#000",
      editable: true,
    });

    canvas.add(text);
    canvas.setActiveObject(text);
    canvas.renderAll();
  };

  const changeFont = (event) => {
    const canvas = canvasRef.current;
    const activeObject = canvas.getActiveObject();

    if (!activeObject || activeObject.type !== "textbox") {
      alert("Seleziona un testo per modificare il font!");
      return;
    }

    const font = event.target.value;
    activeObject.set("fontFamily", font);
    canvas.renderAll();
  };

  const changeTextColor = (event) => {
    const canvas = canvasRef.current;
    const activeObject = canvas.getActiveObject();

    if (!activeObject || activeObject.type !== "textbox") {
      alert("Seleziona un testo per modificare il colore!");
      return;
    }

    const color = event.target.value;
    activeObject.set("fill", color);
    canvas.renderAll();
  };

  const toggleBold = () => {
    const canvas = canvasRef.current;
    const activeObject = canvas.getActiveObject();

    if (!activeObject || activeObject.type !== "textbox") {
      alert("Seleziona un testo per applicare il grassetto!");
      return;
    }

    activeObject.fontWeight = activeObject.fontWeight === "bold" ? "normal" : "bold";
    canvas.renderAll();
  };

  const toggleItalic = () => {
    const canvas = canvasRef.current;
    const activeObject = canvas.getActiveObject();

    if (!activeObject || activeObject.type !== "textbox") {
      alert("Seleziona un testo per applicare il corsivo!");
      return;
    }

    activeObject.fontStyle = activeObject.fontStyle === "italic" ? "normal" : "italic";
    canvas.renderAll();
  };

  const toggleUnderline = () => {
    const canvas = canvasRef.current;
    const activeObject = canvas.getActiveObject();

    if (!activeObject || activeObject.type !== "textbox") {
      alert("Seleziona un testo per applicare la sottolineatura!");
      return;
    }

    activeObject.underline = !activeObject.underline;
    canvas.renderAll();
  };

  const alignText = (alignment) => {
    const canvas = canvasRef.current;
    const activeObject = canvas.getActiveObject();

    if (!activeObject || activeObject.type !== "textbox") {
      alert("Seleziona un testo per modificarne l'allineamento!");
      return;
    }

    activeObject.textAlign = alignment;
    canvas.renderAll();
  };



  const centerHorizontally = () => {
    const canvas = canvasRef.current;
    const activeObject = canvas.getActiveObject();

    if (!activeObject) {
      alert("Nessun oggetto selezionato!");
      return;
    }

    // Calcolare il centro del canvas
    const centerPosition = (canvasSize.width - activeObject.width * activeObject.scaleX) / 2;

    activeObject.set({ left: centerPosition });
    activeObject.setCoords();
    canvas.renderAll();
  };

  const centerVertically = () => {
    const canvas = canvasRef.current;
    const activeObject = canvas.getActiveObject();

    if (!activeObject) {
      alert("Nessun oggetto selezionato!");
      return;
    }

    activeObject.top = (canvasSize.height - activeObject.height * activeObject.scaleY) / 2;
    activeObject.setCoords();
    canvas.renderAll();
  };

  const alignLeft = () => {
    const canvas = canvasRef.current;
    const activeObject = canvas.getActiveObject();

    if (!activeObject) {
      alert("Nessun oggetto selezionato!");
      return;
    }

    // Calcola la metà sinistra del canvas
    const leftHalfWidth = canvasSize.width / 2;

    // Aggiungi un offset per compensare il manico
    const handleOffset = canvasSize.width * 0.1;

    // Calcolare la posizione centrata nella metà sinistra
    const leftPosition = (leftHalfWidth - activeObject.width * activeObject.scaleX - handleOffset) / 2;

    activeObject.set({ left: leftPosition });
    activeObject.setCoords();
    canvas.renderAll();
  };

  const alignRight = () => {
    const canvas = canvasRef.current;
    const activeObject = canvas.getActiveObject();

    if (!activeObject) {
      alert("Nessun oggetto selezionato!");
      return;
    }

    // Calcola la metà destra del canvas
    const rightHalfStart = canvasSize.width / 2;

    // Aggiungi un offset per compensare il manico
    const handleOffset = canvasSize.width * 0.1;

    // Calcolare la posizione centrata nella metà destra
    const rightPosition = rightHalfStart + (canvasSize.width / 2 - activeObject.width * activeObject.scaleX + handleOffset) / 2;

    activeObject.set({ left: rightPosition });
    activeObject.setCoords();
    canvas.renderAll();
  };

  const bringToFront = () => {
    const canvas = canvasRef.current;
    const activeObject = canvas.getActiveObject();

    if (!activeObject) {
      alert("Nessun oggetto selezionato!");
      return;
    }

    const objects = canvas.getObjects();

    activeObject.zIndex = objects.length;

    objects
      .sort((a, b) => (a.zIndex || 0) - (b.zIndex || 0))
      .forEach((obj, index) => {
        obj.zIndex = index;
      });

    canvas.sortObjects();
    canvas.renderAll();
  };

  const sendToBack = () => {
    const canvas = canvasRef.current;
    const activeObject = canvas.getActiveObject();

    if (!activeObject) {
      alert("Nessun oggetto selezionato!");
      return;
    }

    const objects = canvas.getObjects();

    activeObject.zIndex = -1;

    objects
      .sort((a, b) => (a.zIndex || 0) - (b.zIndex || 0))
      .forEach((obj, index) => {
        obj.zIndex = index;
      });

    canvas.sortObjects();
    canvas.renderAll();
  };

  return (
      <div style={styles.container}>
        <div style={styles.toolbar}>
          <div style={styles.optionContainer}>
            {/* Opzione per il Crop */}
            <label style={styles.checkboxLabel}>
              <input
                  type="checkbox"
                  checked={isCropEnabled}
                  onChange={(e) => setIsCropEnabled(e.target.checked)}
                  style={styles.checkbox}
              />
              Cornice
            </label>

            {/* Opzione per il Gradient */}
            <label style={styles.checkboxLabel}>
              <input
                  type="checkbox"
                  checked={isGradientEnabled}
                  onChange={(e) => setIsGradientEnabled(e.target.checked)}
                  style={styles.checkbox}
              />
              Sfumatura
            </label>
          </div>
        </div>

        <div style={styles.toolbar}>
          <button onClick={resetCanvas} title="Reset" style={styles.button}>
            <FaRedoAlt size={20}/>
          </button>
          <button onClick={handleImageUpload} title="Carica Immagine" style={styles.button}>
            <FaImage size={20}/>
          </button>
          <button onClick={addText} title="Aggiungi Testo" style={styles.button}>
            <FaTextHeight size={20}/>
          </button>
          <span></span>
          {isAdmin && (
              <input
                  type="file"
                  onChange={loadProject}
                  accept=".json"
                  style={{display: "none"}}
                  id="projectLoader"
              />
          )}
          {isAdmin && (
              <button onClick={() => document.getElementById("projectLoader").click()} style={styles.button}
                      title="Carica Progetto">
                <FaFileUpload size={20}/>
              </button>
          )}
          {isAdmin && (
              <button onClick={saveProject} style={styles.button} title="Salva Progetto">
                <FaFileDownload size={20}/>
              </button>
          )}
          {isAdmin && (
              <button onClick={downloadHighQualityImage} style={styles.button} title="Esporta in Alta Qualità">
                <FaFileExport size={20}/>
              </button>
          )}
          {isAdmin && (
              <span></span>
          )}
          <button onClick={duplicateSelectedObject} title="Duplica Oggetto" style={styles.button}>
            <FaLayerGroup size={20}/>
          </button>
          <button onClick={deleteSelectedObject} title="Elimina Oggetto" style={styles.button}>
            <FaTrash size={20}/>
          </button>
          <span></span>
          <button
              onClick={generateMugTexture}
              style={styles.buttonGenerate}
              title="Genera Tazza Personalizzata"
              disabled={isLoading}
          >
            {isLoading ? (
                <GrInProgress size={20}/>
            ) : (
                <FaCartPlus size={20}/>
            )}
          </button>
        </div>

        <div style={styles.toolbar}>
          <button onClick={fitObjectToCanvas} title="Adatta Oggetto al Canvas" style={styles.button}>
            <FaExpandArrowsAlt size={20}/>
          </button>
          <span></span>
          <button onClick={centerHorizontally} title="Centra Orizzontalmente" style={styles.button}>
            <MdOutlineAlignHorizontalCenter size={20}/>
          </button>
          <button onClick={centerVertically} title="Centra Verticalmente" style={styles.button}>
            <MdOutlineAlignVerticalCenter size={20}/>
          </button>
          <span></span>
          <button onClick={alignLeft} title="Allinea a Sinistra" style={styles.button}>
            <ImMug size={20} style={{transform: "scaleX(-1)"}}/>
          </button>
          <button onClick={alignRight} title="Allinea a Destra" style={styles.button}>
            <ImMug size={20}/>
          </button>
          <span></span>
          <button onClick={bringToFront} title="Porta in Primo Piano" style={styles.button}>
            <FaRegArrowAltCircleUp size={20}/>
          </button>
          <button onClick={sendToBack} title="Porta in Secondo Piano" style={styles.button}>
            <FaRegArrowAltCircleDown size={20}/>
          </button>
        </div>

        <div style={styles.textToolbar}>
          <button onClick={() => alignText("left")} title="Allinea a Sinistra" style={styles.button}>
            <BsTextLeft size={20}/>
          </button>
          <button onClick={() => alignText("center")} title="Allinea al Centro" style={styles.button}>
            <BsTextCenter size={20}/>
          </button>
          <button onClick={() => alignText("right")} title="Allinea a Destra" style={styles.button}>
            <BsTextRight size={20}/>
          </button>
          <button onClick={() => alignText("justify")} title="Giustifica" style={styles.button}>
            <BsJustify size={20}/>
          </button>
          <span></span>
          <button onClick={toggleBold} title="Grassetto" style={styles.button}>
            <BiBold size={20}/>
          </button>
          <button onClick={toggleItalic} title="Corsivo" style={styles.button}>
            <BiItalic size={20}/>
          </button>
          <button onClick={toggleUnderline} title="Sottolineato" style={styles.button}>
            <BiUnderline size={20}/>
          </button>
          <span></span>
          <select onChange={changeFont} style={styles.select}>
            <option value="Arial">Arial</option>
            <option value="Courier New">Courier New</option>
            <option value="Times New Roman">Times New Roman</option>
            <option value="Verdana">Verdana</option>
          </select>
          <span></span>
          <input
              type="color"
              onChange={changeTextColor}
              style={styles.colorInput}
              title="Cambia Colore Testo"
          />
        </div>

        <div style={styles.editor}>
          <input id="imageUploader" type="file" onChange={addImage} style={{display: "none"}}/>
          <canvas id="editorCanvas" style={{
            width: `${canvasSize.width}px`,
            height: `${canvasSize.height}px`,
            border: "2px dashed #000",
            minHeight: "auto",
          }}></canvas>
        </div>
      </div>
  );
};

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100vh",
    backgroundColor: "#f5f5f5",
    position: "relative",
  },
  toolbar: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    gap: "10px",
    marginBottom: "10px",
  },
  optionContainer: {
    display: "flex",
    flexDirection: "center",
    gap: "10px",
  },
  checkbox: {
    width: "18px",
    height: "18px",
    accentColor: "#007bff", // Colore personalizzato
    cursor: "pointer",
  },
  checkboxLabel: {
    fontSize: "14px",
    display: "flex",
    alignItems: "center",
    gap: "5px",
  },
  textToolbar: {
    display: "flex",
    gap: "10px",
    marginBottom: "10px",
    backgroundColor: "#f1f1f1",
    padding: "5px 10px",
    borderRadius: "5px",
    flexWrap: "wrap", // Permette di andare a capo se necessario
    justifyContent: "flex-start", // Allinea a sinistra per aspetto più naturale
  },
  button: {
    backgroundColor: "#007bff",
    color: "#fff",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
    width: "43px",   // Larghezza fissa
    height: "43px",  // Altezza fissa
    display: "flex", // Per centrare l'icona
    justifyContent: "center", // Centra orizzontalmente
    alignItems: "center",     // Centra verticalmente
  },
  buttonGenerate: {
    backgroundColor: "#28a745",
    color: "#fff",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
    width: "43px",   // Larghezza fissa
    height: "43px",  // Altezza fissa
    display: "flex", // Per centrare l'icona
    justifyContent: "center", // Centra orizzontalmente
    alignItems: "center",     // Centra verticalmente
  },
  select: {
    padding: "5px",
    borderRadius: "5px",
    minWidth: "120px", // Larghezza minima per il font selector
    maxWidth: "200px", // Larghezza massima per il font selector
  },
  colorInput: {
    border: "none",
    cursor: "pointer",
    padding: "5px",
    borderRadius: "5px",
    width: "43px",
    height: "43px",
  },
  editor: {
    position: "relative",
    // border: "2px solid #000",
    padding: 10,
    // backgroundColor: "#ffffff",
  },
  // canvas: {
  //   border: "2px dashed #000",
  //   width: "100%",
  //   height: "auto",
  //   // maxWidth: "1110px", // Canvas responsivo
  //   maxWidth: "768px", // Canvas responsivo
  //   minHeight: "auto",
  // },
};

export default Editor;